//  Tokens that take fee on transfer
const feeOnTransferTokens: Array<{ chainId: number; address: string }> = [
  { chainId: 56, address: '0xcb5edcb7a2ade21ae4a695a5c7ffb7aa01dfaa5d' },
  { chainId: 56, address: '0xca04d1ad684cec6300138ee24040c139e8cc0fb4' },
  { chainId: 56, address: '0x8076c74c5e3f5852037f31ff0093eeb8c8add8d3' },
  { chainId: 56, address: '0x380624a4a7e69db1ca07deecf764025fc224d056' },
  { chainId: 56, address: '0x82da15e36f23a4b603bc8a57c27813f2faeb04b2' },
  { chainId: 56, address: '0xb27adaffb9fea1801459a1a81b17218288c097cc' },
  { chainId: 56, address: '0x8597ba143ac509189e89aab3ba28d661a5dd9830' },
  { chainId: 56, address: '0x0cf011a946f23a03ceff92a4632d5f9288c6c70d' },
  { chainId: 56, address: '0x8850d2c68c632e3b258e612abaa8fada7e6958e5' },
  { chainId: 56, address: '0xf8d39813f16a799669fd4dd60aa0a7920ea9a204' },
  { chainId: 56, address: '0x1af3b59a839e97e944c65177ab3a024b499133f8' },
  { chainId: 56, address: '0xdb6088a2514a3330fa73d7d6e8161a018893de92' },
  { chainId: 56, address: '0x2b04abea8866315a2f2c5ba5d8de656e40874d75' },
  { chainId: 56, address: '0xa57ac35ce91ee92caefaa8dc04140c8e232c2e50' },
  { chainId: 56, address: '0xb7cef49d89321e22dd3f51a212d58398ad542640' },
  { chainId: 56, address: '0x182c763a4b2fbd18c9b5f2d18102a0ddd9d5df26' },
  { chainId: 56, address: '0x073c15a67cb847661ab7284c55b20f413fcfd60f' },
  { chainId: 56, address: '0x5a084bc88939f7873443c4c29b684575ced8c969' },
  { chainId: 56, address: '0xa96f3414334f5a0a529ff5d9d8ea95f42147b8c9' },
  { chainId: 56, address: '0x147840a86eba620be880caa5890d82916c860ad3' },
  { chainId: 56, address: '0x706954491ebfd6b38e5b1e6f079dbefae0f5eba0' },
  { chainId: 56, address: '0x32c4b5d79e44aa7594fac11c0b6d7f61f3ae6429' },
  { chainId: 56, address: '0x3c9230f9f6891f6ebfd9f7c26eebf16961e930fb' },
  { chainId: 56, address: '0x4f45f6025be6c65573daa0b9965a75e837848da0' },
  { chainId: 56, address: '0x86c3e4ffacdb3af628ef985a518cd6ee22a22b28' },
  { chainId: 56, address: '0x31ffbe9bf84b4d9d02cd40eccab4af1e2877bbc6' },
  { chainId: 56, address: '0xecce56d172d4380422e8f8a22044fbc130d21cc8' },
  { chainId: 56, address: '0x26898013a78754022aa2165dabae3f01c8f6d9bd' },
  { chainId: 56, address: '0xd27d3f7f329d93d897612e413f207a4dbe8bf799' },
  { chainId: 56, address: '0x3ad9594151886ce8568c1ff615efa2385a8c3a88' },
  { chainId: 56, address: '0xa1fd60c51c0bdac1ff5bb6067a24d61441e39569' },
  { chainId: 56, address: '0x5b2398a75ecf16b832122b2b29c24e7b1367131a' },
  { chainId: 56, address: '0x9130ae8e2826947cf8b6a06333890b4e8a1667c7' },
  { chainId: 56, address: '0x92a42db88ed0f02c71d439e55962ca7cab0168b5' },
  { chainId: 56, address: '0xf27580f6a58ff785c60b7c15fd394ab442aaa451' },
  { chainId: 56, address: '0x069b2619eb24367a46fda638bd1b88aa4dad7879' },
  { chainId: 56, address: '0xc8a784f4d4b72991acef3a97c593b0aaf8926d4c' },
  { chainId: 56, address: '0x9806aec346064183b5ce441313231dff89811f7a' },
  { chainId: 56, address: '0x7b9c3df47f3326fbc0674d51dc3eb0f2df29f37f' },
  { chainId: 56, address: '0xce195c777e1ce96c30ebec54c91d20417a068706' },
  { chainId: 56, address: '0x10e027343977e55bebeaa6fc65804021829d41d4' },
  { chainId: 56, address: '0x6b51231c43b1604815613801db5e9e614914d6e4' },
  { chainId: 56, address: '0x6fdb74841b167ff6a58552d2e00365dc554ed656' },
  { chainId: 56, address: '0x4d691bbece09a34ba5175639639146b25739cf35' },
  { chainId: 56, address: '0xd513417d3ceb6331f21284ebb563952b95620a7e' },
  { chainId: 56, address: '0x2e291e1c9f85a86d0c58ae15621aac005a8b2ead' },
  { chainId: 56, address: '0x3c00f8fcc8791fa78daa4a480095ec7d475781e2' },
  { chainId: 56, address: '0x3ebb316d8c387ef235baa38978cbb9b97c8c3017' },
  { chainId: 56, address: '0x9b9d617d3445f0147ece2322bace8db2768d2770' },
  { chainId: 56, address: '0xaee4e249d8bba67fa48f8d523558ebd1a73583c4' },
  { chainId: 56, address: '0xf13f903675edfd2072ed3990ba2d35d2bc46a02f' },
  { chainId: 56, address: '0x7d1c46b73e7745b880bcaf9127522580647bd07e' },
  { chainId: 56, address: '0xad90c05bc51672eedfee36e58b3ff1a78bbc146d' },
  { chainId: 56, address: '0xb93ba7dc61ecfced69067151fc00c41ca369a797' },
  { chainId: 56, address: '0xf6362473bd5f893ffe4e6f12866b6bcf23824627' },
  { chainId: 56, address: '0x05b339b0a346bf01f851dde47a5d485c34fe220c' },
  { chainId: 56, address: '0x279d41f3f78fe5c1f0ba41ae963d6e545113c973' },
  { chainId: 56, address: '0xacfc95585d80ab62f67a14c566c1b7a49fe91167' },
  { chainId: 56, address: '0xfe75cd11e283813ec44b4592476109ba3706cef6' },
  { chainId: 56, address: '0xca4a49d41eab9f26bf045614ab4e0047fc00a750' },
  { chainId: 56, address: '0x355ad7abb7bdd56bec94c068f3abbcb2e2571d0d' },
  { chainId: 56, address: '0x6466849a30247d90f0c228a6c4b6b106ff18cab9' },
  { chainId: 56, address: '0x035f0470755dbb305fb2b4ff775fb7b4ce2354e5' },
  { chainId: 56, address: '0xd32751cd37762d247809c85b1d24cb6d2a18bddf' },
  { chainId: 56, address: '0xa77595c72a2ffcc0affe8a7a9f191a99ad08ba24' },
  { chainId: 56, address: '0x40089141c40d0ae0f129e36b9ab152377718d6e3' },
  { chainId: 56, address: '0x270371c58d9d775ed73971dd414656107384f235' },
  { chainId: 56, address: '0x31045e7023e6c388f9447e632a3f9eaff90393fa' },
  { chainId: 56, address: '0xd856b90180a6c420073e46ba40ff114a7221dd47' },
  { chainId: 56, address: '0xd37c1417da7bf5b02ffdea8d5427022dc88a0ee2' },
  { chainId: 56, address: '0x05c6947918f6ac75920bc73bdcbd4d7ee5b04c50' },
  { chainId: 56, address: '0x96fa007ba70e3872227d33e023de1e7e7a888cee' },
  { chainId: 56, address: '0xb2152aba74a731cb28dc09d93d11ad99cc398e00' },
  { chainId: 56, address: '0x6ff2d9e5891a7a7c554b80e0d1b791483c78bce9' },
  { chainId: 56, address: '0x064040d871e23d543a4bc0044413e11592cd97ba' },
  { chainId: 56, address: '0x7d2346a977941a193292ae1b6708f37665c603ff' },
  { chainId: 56, address: '0x40b165fd5ddc75ad0bddc9add0adabff5431a975' },
  { chainId: 56, address: '0xb2152aba74a731cb28dc09d93d11ad99cc398e00' },
  { chainId: 56, address: '0xadcc5099aae3377d649f3d8a9ef108c5e31aaed6' },
  { chainId: 56, address: '0x88d8025a22722a7d7550795038194cfcbaad327e' },
  { chainId: 56, address: '0xf21bab3dbe48743e29d5e18a417401e16f2629e0' },
  { chainId: 56, address: '0x52d86850bc8207b520340b7e39cdaf22561b9e56' },
  { chainId: 56, address: '0x01ec3200a0895f2c92a9e38a104362442d77658b' },
  { chainId: 56, address: '0xd5a0dad4e6f399cdc346ff04fd90ec0b511fcef9' },
  { chainId: 56, address: '0x48207f66b99ed4b5aa5d24f6af17b2d33be691c9' },
  { chainId: 56, address: '0xec4949a82cd52e1ac1124cd5f7d58d3f8ee56bd7' },
  { chainId: 56, address: '0x09d42f9600fe258b4b0c1bde49505256d49b0b90' },
  { chainId: 56, address: '0xfd9eb35b096af16006d0c8e48295128e07c8b584' },
  { chainId: 56, address: '0x0eeb81e29bd245e3c501d55709a0493916d002f2' },
  { chainId: 56, address: '0xd07c4c1a2a1bccc42dbc1ae740d92009ef57abd2' },
  { chainId: 56, address: '0xed438051437c22a9ef249b68c7e292435fe8b858' },
  { chainId: 56, address: '0xb7f2bca9b034f8cc143339dd12bb31d3d50cf27a' },
  { chainId: 56, address: '0x050787de0cf5da03d9387b344334d51cae5dd0fd' },
  { chainId: 56, address: '0x6eb6b5ca233140e501b24e6c355c7ebfd2d35219' },
  { chainId: 56, address: '0xf6571b93adc7d5fd708934bc9baea013321f3872' },
  { chainId: 56, address: '0x4a6309ea1c386efee3f6fcdfad800a7936cf3d1f' },
  { chainId: 56, address: '0xce5814efff15d56efd8025b9f2006d4d7d640b9b' },
  { chainId: 56, address: '0xd7b729ef857aa773f47d37088a1181bb3fbf0099' },
  { chainId: 56, address: '0xfb54c7a26491560a1839df04afc20a46a8020049' },
  { chainId: 56, address: '0xc6d736123fa8e8a5d85803b5c22799e394245fab' },
  { chainId: 56, address: '0xced745b7c37eb1bc243b90da962f907022d1b219' },
  { chainId: 56, address: '0x3cfdfbea0d3aa5b445836104be7f1d9bee038f3e' },
  { chainId: 56, address: '0x1d2cc85e1093de8d4754562bcf0c18512c659cd4' },
  { chainId: 56, address: '0x18d883f6647cb3195f55eb93bf9ee8ae824e3a6f' },
  { chainId: 56, address: '0xef2ec90e0b8d4cdfdb090989ea1bc663f0d680bf' },
  { chainId: 56, address: '0xee559996ddc8ab4d7186c62ce118a8494d58f4e4' },
  { chainId: 56, address: '0x782a94ca5a8fc2b2bad4a54efafb416964ba3cbf' },
  { chainId: 56, address: '0x930a3dcb00985c440bf09fcf16245b1e1ee70d89' },
  { chainId: 56, address: '0xa5660c2070faecfc231fd6bd743fe88382f2991d' },
  { chainId: 56, address: '0x13da6e58ffe1540d7ae125b76c5f16a4063754cd' },
  { chainId: 56, address: '0xee4ae7b04e9a31c105cb0466efec72d7ce8cea56' },
  { chainId: 56, address: '0x6306e883493824ccf606d90e25f68a28e47b98a3' },
  { chainId: 56, address: '0x7e4692f69c0bf1fe77deebdd5bebeafa55f21a21' },
  { chainId: 56, address: '0x9e8bbe508cb5db41a23a962398405c71436fdf28' },
  { chainId: 56, address: '0x81e4d494b85a24a58a6ba45c9b418b32a4e039de' },
  { chainId: 1, address: '0x4c1f922ec4c18312efc384bf58cf72271af260ce' },
]

export { feeOnTransferTokens }
