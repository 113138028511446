/**
 * Events should match those here https://www.notion.so/dexguru/964ee9be37124dc79f36659f60d5ca55?v=be587cb823c7496eb32fb1635a1cf464
 * Properties here https://www.notion.so/dexguru/554de48c2a874e9ea996d72bb9dc00ce?v=07ea3b4953f64be8b383c66b6dd86537
 */

export enum AmplitudeEvent {
  ACCOUNTS_SETTINGS_UPDATES = 'Account Settings Updated',

  TOKEN_ADDED_TO_FAVORITES = 'Token Added to Favorites',

  TOKEN_REMOVED_FROM_FAVORITES = 'Token Removed from Favorites',

  WALLET_CONNECTED = 'Wallet Connected',

  WALLET_DISCONNECTED = 'Wallet Disconnected',

  MARKET_SELECTOR_OPENED = 'Market Selector Opened',

  BASE_CURRENCY_SWITCHED = 'Base Currency Switched',

  TOKEN_INFORMATION_VIEWED = 'Token Information Viewed',

  TOP_VOLUME_TAB_VIEWED = 'Top Volume Tab Viewed',

  FAVORITES_TAB_VIEWED = 'Favorites Tab Viewed',

  WALLET_TAB_VIEWED = 'Wallet Tab Viewed',

  CLICKED_WALLET_IN_HEADER = 'Clicked Wallet in Header',

  TOKEN_SELECTED_FROM_MARKET_SELECTOR = 'Token Selected from Market Selector',

  TRANSACTION_COMPLETED = 'Transaction Completed',

  INTERACTED_WITH_BUY_SELL_SECTION = 'Interacted with Buy/Sell Section',

  INTERACTED_WITH_LEFT_COLUMN = 'Interacted with Left Column',

  INTERACTED_WITH_RIGHT_COLUMN = 'Interacted with Right Column',

  LEFT_SIDEBAR_OPENED_TO_FULL_SCREEN = 'Left Sidebar Opened to Full Screen',

  RIGHT_SIDEBAR_OPENED_TO_FULL_SCREEN = 'Right Sidebar Opened to Full Screen',

  ADD_TO_METAMASK_FROM_POST_SWAP = 'Add to MetaMask from Post Swap',

  ADJUSTED_TIME_ON_EXPANDED_TOKEN_BALANCE_CHART = 'Adjusted Time on Expanded Token Balance Chart',

  ADJUSTED_TIME_ON_EXPANDED_TOP_TOKEN_HOLDER_CHART = 'Adjusted Time on Expanded Top Token Holder Chart',

  APPLIED_FILTER_ON_LIQUIDITY_POOL_HISTORY = 'Applied Filter on Liquidity Pool History',

  APPLIED_FILTER_ON_TOKEN_PROFILE_TX_LIST = 'Applied Filter on Token Profile TX List',

  APPLIED_FILTER_ON_TRADER_PROFILE_TX_LIST = 'Applied Filter on Trader Profile TX List',

  APPLIED_FILTER_ON_TRADING_TRANSACTION_HISTORY = 'Applied Filter on Trading Transaction History',

  APPLIED_QUICK_FILTER_FROM_ADDRESS_ON_TOKEN_PROFILE = 'Applied Quick Filter from Address on Token Profile',

  CHANGED_TIME_PERIOD_ON_TOTAL_LIQUIDITY_CHART = 'Changed Time Period on Total Liquidity Chart',

  CHANGED_TIME_PERIOD_ON_TRADING_VOLUME_CHART = 'Changed Time Period on Trading Volume Chart',

  CHANGED_TOKEN_PAGE_LAYOUT = 'Changed Token Page Layout',

  COLLAPSED_LEFT_LIQUIDITY_SIDEBAR = 'Collapsed Left Liquidity Sidebar',

  COLLAPSED_RIGHT_TRADING_SIDEBAR = 'Collapsed Right Trading Sidebar',

  EXPANDED_TOKEN_BALANCE_ROW = 'Expanded Token Balance Row',

  COLLAPSED_TOKEN_BALANCE_ROW = 'Collapsed Token Balance Row',

  EXPANDED_TOP_TOKEN_HOLDERS_ROW = 'Expanded Top Token Holders Row',

  COLLAPSED_TOP_TOKEN_HOLDERS_ROW = 'Collapsed Top Token Holders Row',

  FAVORITED_TOKEN_FROM_POST_SWAP = 'Favorited Token from Post Swap',

  FAVORITED_LIST_OPENED_FROM_HEADER = 'Favorites List Opened from Header',

  FILTERED_LIQUIDITY_HISTORY_ON_LIQUIDITY_SIDEBAR = 'Filtered Liquidity History on Liquidity Sidebar',

  FILTERED_ORDER_ON_TRADING_SIDEBAR = 'Filtered Orders on Trading Sidebar',

  GAINERS_TAB_VIEWED = 'Gainers Tab Viewed',

  CLICKED_GAINERS_IN_HEADER = 'Clicked Gainers in Header',

  INTERACTED_WITH_HOLDERS_INFORMATION_SECTION = 'Interacted with Holders Information Section',

  INTERACTED_WITH_TOKEN_INFORMATION = 'Interacted with Token Information',

  INTERACTED_WITH_TOKEN_LINKS = 'Interacted with Token Links',

  INTERACTED_WITH_TRADER_LINKS = 'Interacted with Trader Links',

  LOSERS_TAB_VIEWED = 'Losers Tab Viewed',

  CLICKED_LOSERS_IN_HEADER = 'Clicked Losers in Header',

  OPENED_CHAIN_DROP_DOWN = 'Opened Chain Drop Down',

  OPENED_CHANGE_PAGE_LAYOUT = 'Opened Change Page Layout',

  OPENED_FILTER_ON_LIQUIDITY_POOL_HISTORY = 'Opened Filter on Liquidity Pool History',

  OPENED_FILTER_ON_TRADER_PROFILE = 'Opened Filter on Trader Profile',

  OPENED_FILTER_ON_TOKEN_PROFILE = 'Opened Filter on Token Profile',

  OPENED_FILTER_ON_TRADING_TRANSACTION_HISTORY = 'Opened Filter on Trading Transaction History',

  OPENED_SETTINGS_MODAL = 'Opened Settings Modal',

  OPENED_TWITTER_FROM_POST_SWAP = 'Opened Twitter from Post Swap',

  OPENED_USER_ONBOARDING = 'Opened User Onboarding',

  RECENTLY_LISTED_VIEWED = 'Recently Listed Viewed',

  CLICKED_RECENTLY_LISTED_IN_HEADER = 'Clicked Recently Listed in Header',

  SELECT_CHAIN_FROM_DROP_DOWN = 'Select Chain from Chain Drop Down',

  SWAP_TRANSACTION_FAILED = 'Swap Transaction Failed',

  TOGGLED_SOUND_EFFECTS = 'Toggled Sound Effects',

  TRENDING_TAB_VIEWED = 'Trending Tab Viewed',

  CLICKED_TRENDING_IN_HEADER = 'Clicked Trending in Header',

  CLICKED_TOP_VOLUME_IN_HEADER = 'Clicked Top Volume in Header',

  VIEWED_TX_ON_BLOCK_EXPLORER = 'Viewed TX on Block Explorer',

  WALLET_BUTTON_CLICKED = 'Wallet Button Clicked',

  WALLET_CONNECTED_BUTTON_CLICKED = 'Wallet Connected Button Clicked',

  CLICKED_ADD_CHAT = 'Clicked Add Chart',

  CLICKED_NEW_GROUP = 'Clicked New Group',

  SUCCESSFULLY_ADDED_CHART = 'Successfully Added Chart',

  SUCCESSFULLY_CREATE_NEW_GROUP = 'Successfully Created New Group',

  SWITCHED_CHART_GROUP = 'Switched Chart Group',

  CLICKED_ON_MULTICHART_IN_HEADER = 'Clicked on Multi-Chart in Header',

  CLICKED_TRADER_PROFILE_IN_WALLET_MODAL = 'Clicked Trader Profile in Wallet Modal',

  COPIED_ADDRESS_IN_WALLET_MODAL = 'Copied Address in Wallet Modal',

  OPENED_ZERION_IN_WALLET_MODAL = 'Opened Zerion in Wallet Modal',

  OPENED_BLOCKCHAIN_EXPLORER_IN_WALLET_NETWORK = 'Opened Blockchain Explorer in Wallet Modal',

  CHANGED_TRADING_VOLUME_CHART_BY_TRADER_CATEGORY = 'Changed Trading Volume Chart by Trader Category',

  CHANGED_TRADING_VOLUME_CHART_BY_MARKET = 'Changed Trading Volume Chart by Market',

  ALERTS_AND_NOTIFICATIONS_SIDEBAR_OPENED = 'Alerts and Notifications Sidebar Opened',

  OPENED_MY_ALERTS = 'Opened My Alerts',

  OPENED_ALERT_FEED = 'Opened Alert Feed',

  CLICKED_CREATE_ALERT = 'Clicked Create Alert',

  CLICK_CREATE_FAVORITED_TOKEN_PRICE_CHANGE_CARD = 'Clicked Create Favorited Token Price Change Card',

  SUCCESSFULLY_CREATED_FAVORITED_TOKEN_PRICE_CHANGE_ALERT = 'Successfully Created Favorited Token Price Change Alert',

  SUCCESSFULLY_EDITED_FAVORITED_TOKEN_PRICE_CHANGE_ALERT = 'Successfully Edited Favorited Token Price Change Alert',

  CANCELLED_SAVE_ALERT_FAVORITED_TOKEN_PRICE_CHANGE = 'Cancelled Save Alert Favorited Token Price Change',

  LIMIT_ORDER_SENT = 'Limit Order Sent',

  LIMIT_ORDER_CANCELED = 'Limit Order Canceled',

  LIMIT_ORDER_FILLED = 'Limit Order Filled',

  LIMIT_ORDE_EXPIRED = 'Limit Order Expired',
}
