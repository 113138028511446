import { BigNumber } from 'bignumber.js'

import { TransactionTypes } from '../model'

export const ETH = 'eth'
export const BSC = 'bsc'
export const POLYGON = 'polygon'
export const AVALANCHE = 'avalanche'
export const NETWORK: Record<string, string> = {
  eth: ETH,
  bsc: BSC,
  polygon: POLYGON,
  avalanche: AVALANCHE,
}
export const MAX_UINT = new BigNumber(2).pow(256).minus(1)
export const DEFAULT_ERROR_MESSAGE = 'Trade estimation error. Please try again.'
export const ZERO_X_ERROR_MESSAGE = '0x api estimation error. Please try again.'
export const LOST_QUOTE_RESPONSE_ERROR_MESSAGE = 'Lost quote response'
export const QUOTE_RESPONSE_ERROR_MESSAGE = 'Quote response error'
export const WALLET_EXECUTE_DELEGATE_ERROR_MESSAGE =
  'Currently we do not support this token because its contract charges an extra fee for any transfer'
export const GAS_ERROR_MESSAGE = 'Gas estimation error. Please try again.'
export const RPC_ERROR_MESSAGE = 'RPC Error. Please try again.'
export const RPC_ERROR_MESSAGE_POLYGON =
  'Try to shift your Polygon RPC on Metamask to https://polygon-rpc.com'

export const enum ZERO_X_ERRORS {
  WalletExecuteDelegateCallFailedError = 'WalletExecuteDelegateCallFailedError',
  PancakeSwapFeature = 'PancakeSwapFeature',
  UnderBought = 'UnderBought',
}

export const DEFAULT_OFFSET = 0

export const defaultRequestFilters: Record<string, unknown> = {
  order: 'desc',
  sort_by: 'timestamp',
  limit: 50,
  with_full_totals: false,
}

export const REQUEST_DENIED_BY_USER_ERROR_CODE = 4001
export const APM_TIMEOUT = 1000
export const BALANCE_POOLING_TIMEOUT = 30000
export const WS_RECONNECT_RETRIES = 3

export const LOADER_TIMEOUT = 30000

export const defaultTransactionType: Record<string, TransactionTypes[]> = {
  'pool-activity': [TransactionTypes.mint, TransactionTypes.burn],
  'profile-trading-history': [TransactionTypes.swap, TransactionTypes.transfer],
  'trading-history': [TransactionTypes.swap],
}

export const TVCHART_MAIN_LABEL = 'tvchart_main'
export const WS_CHART_DELAY = 1000

export const ERROR_BOUNDARY = 'app-boundary'
export const TOKEN_CACHE_TTL_SEC = 60
export const WS_SUBSCRIBE_FAILED_CODE = 'WS-002'

export const VERSION_SNACKBAR_KEY = 'version'
export const FREEZE_SNACKBAR_KEY = 'freeze'
export const MOBILE_ENABLE_CHART_KEY = 'isMobileChartEnabled'
export type HeadingSize = 'xl' | 'lg' | 'md' | 'sm' | 'xs' | 'xxs'

export const isPaywallEnabled = process.env.REACT_APP_TGE_PAYWALL === 'true'
